import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { css } from "@emotion/react";

export default function NotFound() {
	return (
		<>
			<Layout>
				<h2>Page not found :(</h2>
				<div
					css={css`
						font-size: 1rem;
						font-weight: 400;
					`}
				>
					<p>Were you looking for these instead?</p>
					<ul>
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/work">Projects</Link>
						</li>
						<li>
							<Link to="/blog">Blog posts</Link>
						</li>
						<li>
							<a href="https://github.com/joeyqlim">GitHub Profile</a>
						</li>
						<li>
							<a href="https://linkedin.com/in/joeyqlim">LinkedIn</a>
						</li>
					</ul>
				</div>
			</Layout>
		</>
	);
}
